import {
  DAY_HOURS_START,
  NIGHT_HOURS_START,
  AIRLINE_COLOR_MAP,
  ERRORS,
  ENVIRONMENTS,
  BASE_ROLES,
  UTC_ZERO,
  DAY_PERIOD,
  TIME_FORMAT_24,
  TIME_FORMAT_12,
  MERIDIAN_CHANGE,
} from './constants';
import moment from 'moment';
import StorageUtil from './StorageUtil';
import { MessageStatus } from './generated/graphql';
import { CheckType } from './hooks/useCheckPermissions';

export const getAuthData = () =>
  window.localStorage['persist:root']
    ? JSON.parse(JSON.parse(window.localStorage['persist:root']).authReducer)
    : null;

export const getSettingsData = () =>
  window.localStorage['persist:root']
    ? JSON.parse(
        JSON.parse(window.localStorage['persist:root']).settingsReducer
      )
    : null;

//Get time in Day
export const getIsDayTime = () => {
  const hours = new Date().getHours();
  return hours >= DAY_HOURS_START && hours < NIGHT_HOURS_START;
};

//Format Times to UTC / 12/24
export const setTimeFormat = ({
  getTime,
  utcFormat,
  format24,
  getTimeFormat = 'H:mm',
  hasAMPM = false,
}) => {
  const format12Hours = hasAMPM ? 'h:mm A' : 'h:mm';
  if (utcFormat === true) {
    return moment(getTime, getTimeFormat)
      .utc()
      .format(format24 ? 'H:mm' : format12Hours);
  }

  if (utcFormat === false) {
    return moment(getTime, getTimeFormat).format(
      format24 ? 'H:mm' : format12Hours
    );
  }
};

export const getTimeObject = (
  hours = 0,
  minutes = 0,
  dayPeriod = DAY_PERIOD.AM,
  is24Format = false,
  isUTC = true,
  UTCDiff = UTC_ZERO
) => {
  const addTwelve =
    dayPeriod.toUpperCase() === DAY_PERIOD.PM &&
    !is24Format &&
    hours < MERIDIAN_CHANGE;

  const hoursToSet = addTwelve
    ? Number(hours) + MERIDIAN_CHANGE
    : Number(hours);

  if (isUTC) {
    return moment(
      `${hours}:${minutes} ${dayPeriod}`,
      is24Format ? TIME_FORMAT_24 : TIME_FORMAT_12
    );
  }

  if (UTCDiff !== UTC_ZERO) {
    return moment(
      `${hoursToSet}:${minutes} ${UTCDiff}`,
      TIME_FORMAT_24
    ).utcOffset(UTCDiff);
  }

  return moment({ hours: hoursToSet, minutes: minutes });
};
export const getAccessToken = () => StorageUtil.get('access_token');
export const getIdToken = () => StorageUtil.get('id_token');
export const getRefreshToken = () =>
  StorageUtil.get('refresh_token') === ''
    ? null
    : StorageUtil.get('refresh_token');
export const getIsTokenRefreshing = () =>
  StorageUtil.get('is_token_refreshing') === 'true';
export const getIsLoginFlowComplete = () =>
  StorageUtil.get('is_login_flow_complete') === 'true';
export const getUserId = () => StorageUtil.get('user_id');
export const isUserLoggedIn = () => !!getAuthData()?.isLoggedIn;

export const hasAccessTokenExpired = () => {
  const authData = getAuthData();

  // verify token validity 5 minutes before it expires

  if (authData.tokenCreatedDate && authData.expiresIn) {
    return (
      Date.now() + 180000 >
      authData.tokenCreatedDate + authData.expiresIn * 1000
    );
  }
  return false;
};

export const encodeUriComponent = (details) => {
  let formBody = [];
  for (let property in details) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }
  formBody = formBody.join('&');

  return formBody;
};

export const validateEmail = (value) => {
  if (
    value === '' ||
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
  ) {
    return {
      isError: false,
      errorMessage: '',
    };
  }
  return {
    isError: true,
    errorMessage: 'Invalid input',
  };
};

/**
 * Validates a string value as a name
 *
 * A valid name must consist of at least three letters, spaces, and hyphens
 * The name must begin and end with a letter or a non-space character
 *
 * @param {string} value - The value to validate
 * @return {{isError: boolean; errorMessage: string;}} - True if the value is a valid name, otherwise an error message
 */
export const validateNames = (value, minLength = 1) => {
  const regexPattern = new RegExp(
    `^(?![-\\s])[-\\sa-zA-ZÀ-ž]{${minLength},}(?:(?![-\\s])[-\\sa-zA-ZÀ-ž])$`
  );

  if (value === '' || regexPattern.test(value)) {
    return {
      isError: false,
      errorMessage: '',
    };
  }
  return {
    isError: true,
    errorMessage: 'Invalid input',
  };
};

export const validateStations = (value, minLength = 1) => {
  const regexPattern = new RegExp(
    `^(?![-\\s])[-\\sa-zA-ZÀ-ž]{${minLength},}(?:(?![-\\s])[-\\sa-zA-ZÀ-ž])$`
  );

  const cond = value === '';

  if (cond || regexPattern.test(value)) {
    return {
      isError: false,
      errorMessage: '',
    };
  }
  return {
    isError: true,
    errorMessage: 'Invalid input - 3 letters required',
  };
};

//only numbers and '+' first character
export const validatePhone = (value) => {
  if (value === '' || /\+\d+$/.test(value)) {
    return {
      isError: false,
      errorMessage: '',
    };
  }
  return {
    isError: true,
    errorMessage: 'Invalid input',
  };
};

export const isNotificationInputValid = (value) => {
  return value && value.replace(/\s/g, '').length;
};

export const getThemeColor = (flightName) => {
  const airlineKey = getAirlineKey(flightName);
  return AIRLINE_COLOR_MAP[airlineKey] ?? AIRLINE_COLOR_MAP['default'];
};

export const capitalize = (str) =>
  str.toLowerCase().replace(/(?:^|\s)\S/g, (word) => word.toUpperCase());

const formatFlightNumber = (flightNumber) => {
  flightNumber = flightNumber?.replace(/^0+/, '');
  while (flightNumber?.length < 3) {
    flightNumber = '0' + flightNumber;
  }
  return flightNumber;
};

export const getFlightName = (str = '') => {
  const airlineKey = str.slice(8, 10);
  const id = formatFlightNumber(str?.split('-')?.[1]);
  const day = str.slice(6, 8);

  return `${airlineKey} ${id}/${day}`;
};

export const getDateInMilliseconds = (date) => {
  const format = 'DD-MM-YYYY HH:mm:ss';
  return moment(date, format).valueOf();
};

export const getErrorMessage = (errorsReceived, errorEncountered) => {
  if (Array.isArray(errorsReceived) && errorsReceived.length > 0) {
    const firstError = errorsReceived[0];
    return [firstError, firstError.message || ERRORS.NO_DATA];
  }
  if (errorsReceived?.message) {
    return [errorsReceived, errorsReceived.message];
  }
  if (!errorsReceived?.length && errorEncountered?.length) {
    const firstError = errorEncountered[0];
    return [firstError, firstError.message || ERRORS.NO_DATA];
  }
  return errorsReceived.length ? [errorEncountered, ERRORS.NO_DATA] : [];
};

export const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const toTitleCase = (str) =>
  str
    .split(' ')
    .map((substr) => substr.charAt(0).toUpperCase() + substr.slice(1))
    .join(' ');

export const fetchApi = async (url, options = {}) => {
  const response = await fetch(url, options);

  if (!response.ok) {
    throw new Error(`Request failed with status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const generateVerifier = () => {
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const randomValues = crypto.getRandomValues(new Uint8Array(10));

  return Array.from(randomValues)
    .map((randomValue) => {
      const randomIndex = randomValue % charset.length;
      return charset[randomIndex];
    })
    .join('');
};

export const generateChallenge = async (code_verifier) => {
  const buffer = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(code_verifier)
  );
  return btoa(String.fromCharCode(...new Uint8Array(buffer)))
    .replace(/\//g, '_')
    .replace(/\+/g, '-')
    .replace(/=/g, '');
};

export const getCodeChallenge = async () => {
  const codeVerifier = generateVerifier();
  return await generateChallenge(codeVerifier);
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const scrollToBottomOfContainer = (containerId) => {
  const messagesContainer = document.getElementById(containerId);
  if (messagesContainer) {
    messagesContainer.scrollTop = messagesContainer.scrollHeight;
  }
};

export const getIsTouchDevice = () => {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
};

export const getUnreadMessagesCountByFlightId = (
  chatMessages,
  flightId,
  currentRole
) => {
  const conversations = chatMessages?.conversations || [];
  return conversations.reduce((count, flight) => {
    if (flight.flightId === flightId && flight.chatId.includes(currentRole)) {
      const unreadMessages =
        flight.messages?.filter(
          (msg) => !msg.isMe && msg.status !== MessageStatus.READ
        )?.length || 0;
      count += unreadMessages;
    }
    return count;
  }, 0);
};

export const getUnreadMessagesCountByChat = (chat) => {
  return (
    chat?.messages?.filter(
      (msg) => !msg.isMe && msg.status !== MessageStatus.READ
    )?.length || 0
  );
};

export const getTotalUnreadMessagesCount = (chatMessages, checkedInRoles) => {
  const conversations = chatMessages?.conversations || [];
  return conversations.reduce((count, flight) => {
    const currentCheckedInFlight = checkedInRoles[flight.flightId];
    if (
      currentCheckedInFlight &&
      flight.chatId.includes(currentCheckedInFlight?.myRole?.roleGroup)
    ) {
      const unreadMessages =
        flight.messages?.filter(
          (msg) => !msg.isMe && msg.status !== MessageStatus.READ
        )?.length || 0;
      count += unreadMessages;
    }
    return count;
  }, 0);
};

const filterRoles = (userRoles, carrier, checkedInRoleIds) => {
  let roles = userRoles;

  if (carrier) {
    roles = userRoles.filter(
      (role) => role.carrier === carrier.toLocaleUpperCase()
    );
  }

  if (checkedInRoleIds) {
    roles = userRoles.filter((role) => checkedInRoleIds.includes(role._id));
  }

  return roles;
};

export const hasPermissions = (
  userRoles,
  requiredPermissions,
  checkingMode,
  carrier,
  checkedInRoleIds
) => {
  const roles = filterRoles(userRoles, carrier, checkedInRoleIds);

  const permissions = new Set(roles?.flatMap((role) => role.permissions));

  if (checkingMode === CheckType.PARTIAL) {
    return requiredPermissions.some((permission) =>
      permissions.has(permission)
    );
  }

  return requiredPermissions.every((permission) => permissions.has(permission));
};

export const getAirlineKey = (flightName) =>
  flightName.split(' ')?.[0].toLowerCase() ?? '';

export const getCarrierFromFlighId = (flightId) => {
  const splittedId = flightId?.split('-') ?? [];
  return splittedId?.[0].substring(8, splittedId?.[0].length);
};

export const checkCUTEDevice = () => {
  if ('cuteapi' in navigator) {
    return true;
  }
  if ('cgroupapi' in navigator) {
    const cgroupenv = navigator.cgroupapi.getenv('CUTE_SW_COMMON_USE_TYPE');

    return cgroupenv === 'CUTE' || cgroupenv === 'CUPPS' || cgroupenv === 'NAT';
  }
  return false;
};

export const hasBaseRole = (environment, roles) => {
  if (environment === ENVIRONMENTS.LOCAL) {
    return true;
  }
  if (environment === ENVIRONMENTS.DEVELOPMENT) {
    return roles?.includes(BASE_ROLES.DEV);
  }
  if (environment === ENVIRONMENTS.UAT) {
    return roles?.includes(BASE_ROLES.UAT);
  }
  return roles?.includes(BASE_ROLES.PROD);
};
