import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useCustomHeader } from '../../utils/hooks/useCustomHeader';
import ReloadButton from '../../components/CustomButtons/ReloadButton/ReloadButton';
import {
  BELOW_WING,
  DEVICE_TYPE,
  GENERAL_NOTIFICATION_ARRAY,
  INITIAL_STATE,
} from '../../utils/constants';
import Grid from '../../components/Grid/Grid';
import HeroBaggage from '../../components/HeroHeader/HeroBaggage';
import SpecialLoads from '../../components/FlightDetails/SpecialLoads/SpecialLoads';
import BagageStatusTable from '../../components/BagageStatusTable/BagageStatusTable';
import { useParams } from 'react-router-dom';
import { getDepartureStationFromId } from '../../utils/flightUtil';
import {
  getThemeColor,
  getErrorMessage,
  getCarrierFromFlighId,
} from '../../utils/helpers';
import { useNotifications } from '../../utils/hooks/useNotifications';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { useGetDetailsHeader } from '../../utils/hooks/useGetDetailsHeader';
import { useGetBelowTheWing } from '../../utils/hooks/useGetBelowTheWing';
import useCheckPermissions, {
  CheckType,
} from '../../utils/hooks/useCheckPermissions';
import { Permission } from '../../utils/generated/graphql';
import useNetworkStatus from '../../utils/hooks/useNetworkStatus';
import { useDeviceBreakpoint } from '../../utils/hooks/useDeviceBreakpoint';
import { useSelector } from 'react-redux';
import { selectUTC } from '../../redux/reducers/settingsReducer';
import { calculateFlightNumber } from '../../utils/calculateFlightNumber';

const colClassNames = 'tablet:col-span-2 laptop:col-span-2 desktop:col-span-2';
const flightsHeaderClassNames = classNames(
  'font-head-light text-18 text-grey-60 leading-[19px] dark:text-grey-40 mb-[33px] mobile:mb-0',
  colClassNames
);
const gridClassNames = classNames('mobile:pt-32', colClassNames);

const BelowTheWing = () => {
  const { flight } = useParams();
  const station = getDepartureStationFromId(flight);
  const isOnline = useNetworkStatus();

  const hasBelowWingDataPermission = useCheckPermissions(
    [Permission.DISPLAY_PROCESS_PAGE_BELOW_WING_DATA],
    CheckType.FULL
  );

  const {
    data,
    loading: isFetchingBelow,
    error: errorEncountered,
    responseErrors: responseErrorsBelow,
    refetchBelowTheWing,
  } = useGetBelowTheWing(flight, station, !hasBelowWingDataPermission);

  const {
    data: dataHeader,
    isFetching: isFetchingHeader,
    error: errorEncounteredHeader,
    responseErrors: responseErrorsHeader,
    refetchDetailsHeader,
  } = useGetDetailsHeader(flight, 'header');

  const {
    baggageStatus: {
      loaded = INITIAL_STATE,
      checkedIn = INITIAL_STATE,
      offload = INITIAL_STATE,
    } = {},
    baggageDetails,
    specialLoads: {
      DGR = INITIAL_STATE,
      AVIH = INITIAL_STATE,
      WCH = INITIAL_STATE,
      WEA = INITIAL_STATE,
      DAA = INITIAL_STATE,
      other = INITIAL_STATE,
    } = {},
  } = data || {};

  const isFetching = isFetchingBelow || isFetchingHeader;
  const [errorsReceived, setErrorsReceived] = useState([]);
  const [errorsEncounteredBaggage, setErrorsEncounteredBaggage] = useState([]);
  const [responseErrorsBaggage, setErrorsBaggage] = useState([]);
  const [allErrorsEncountered, setAllErrorsEncountered] = useState([]);

  useEffect(() => {
    if (!isFetching) {
      let allErrorsReceived = [];
      let allErrorsEncountered = [];

      if (errorEncounteredHeader) {
        allErrorsEncountered.push(errorEncounteredHeader);
      }
      if (errorEncountered) {
        allErrorsEncountered.push(errorEncountered);
      }
      if (errorsEncounteredBaggage) {
        allErrorsEncountered.push(errorsEncounteredBaggage);
      }

      if (allErrorsEncountered.length > 1) {
        allErrorsEncountered = GENERAL_NOTIFICATION_ARRAY;
      }

      setAllErrorsEncountered(allErrorsEncountered);

      if (responseErrorsBelow) {
        allErrorsReceived.push(...responseErrorsBelow);
      }
      if (responseErrorsHeader) {
        allErrorsReceived.push(...responseErrorsHeader);
      }
      if (responseErrorsBaggage) {
        allErrorsReceived.push(...responseErrorsBaggage);
      }
      if (allErrorsReceived.length > 1) {
        allErrorsReceived = GENERAL_NOTIFICATION_ARRAY;
      }
      setErrorsReceived(allErrorsReceived);
    } else {
      setErrorsReceived([]);
    }
  }, [isFetching, dataHeader, data, errorEncountered, errorEncounteredHeader]);
  const [error, errorMessage] = getErrorMessage(
    errorsReceived,
    allErrorsEncountered
  );
  useNotifications(error ? { ...error } : null, errorMessage);
  const {
    flightNumber,
    departureAirport = INITIAL_STATE,
    arrivalAirport = INITIAL_STATE,
    registration = INITIAL_STATE,
    type = INITIAL_STATE,
    bestTimeLogic = { timeLeft: INITIAL_STATE },
    departureTimes = { bestTimeUTC: INITIAL_STATE },
    isCheckedIn,
    MCD,
    showMCD,
  } = dataHeader || {};

  const isUTC = useSelector(selectUTC);
  const calculatedFlightNumber = calculateFlightNumber(isUTC, flightNumber);
  const themeColor = getThemeColor(getCarrierFromFlighId(flight));

  useCustomHeader({
    headerTypeMobile: 'flight',
    departureAirport,
    arrivalAirport,
    type,
    headerTypeTablet: 'flight',
    flightNumber: calculatedFlightNumber,
    registration,
    bestTimeLogic,
    themeColor,
    departureTimes,
    isCheckedIn,
    MCD: MCD,
    showMCD: showMCD,
  });

  const isMobile = useDeviceBreakpoint(DEVICE_TYPE.MOBILE);

  const renderBaggageStatusTable = () => (
    <div
      className={classNames(
        colClassNames,
        'bg-white dark:bg-grey-90 rounded-8'
      )}>
      <BagageStatusTable
        bagageStatusArray={baggageDetails}
        flightId={flight}
        isDataRefetching={isFetching}
        setErrors={setErrorsBaggage}
        setErrorsEncountered={setErrorsEncounteredBaggage}
      />
    </div>
  );

  return (
    <>
      <div className="px-24 pt-32 pb-[84px] mobile:pt-0 mobile:pb-[16px]">
        <div className=" mobile:left-0 w-fit absolute top-24 mobile:top-[16px] z-40 left-[63px] right-0 mx-auto">
          {isFetching ? (
            <LoadingSpinner />
          ) : (
            isOnline && (
              <ReloadButton
                refetchFunctions={[refetchBelowTheWing, refetchDetailsHeader]}
              />
            )
          )}
        </div>
        <Grid col={[3, 16, 10]} mobile={[1, 0, 24]} className={gridClassNames}>
          <h1 className={flightsHeaderClassNames}>{BELOW_WING}</h1>
          <HeroBaggage
            loaded={loaded}
            checkedIn={checkedIn}
            offload={offload}
            className={colClassNames}
            themeColor={themeColor}
          />
          <SpecialLoads
            DGR={DGR}
            AVIH={AVIH}
            WCH={WCH}
            WEA={WEA}
            DAA={DAA}
            other={other}
          />
          {!isMobile && renderBaggageStatusTable()}
        </Grid>
      </div>
      {isMobile && renderBaggageStatusTable()}
    </>
  );
};

export default BelowTheWing;
