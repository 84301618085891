import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useCustomHeader } from '../../utils/hooks/useCustomHeader';
import HeroPassanger from '../../components/HeroHeader/HeroPassanger';
import ConnectionFlights from '../../components/ConnectionFlights/ConnectionFlights';
import ReloadButton from '../../components/CustomButtons/ReloadButton/ReloadButton';
import {
  ABOVE_WING,
  COB_WARNING,
  CONNECTION_FLIGHT_TYPE,
  DEVICE_TYPE,
  FLIGHT_CONNECTIONS,
  GAUGE_LABEL,
  GENERAL_NOTIFICATION_ARRAY,
  HBAG_LABEL,
  INBOUND,
  INITIAL_STATE,
  OUTBOUND,
} from '../../utils/constants';
import Grid from '../../components/Grid/Grid';
import PassengerSpecials from '../../components/FlightDetails/PassengerSpecials/PassengerSpecials';
import {
  getThemeColor,
  getErrorMessage,
  getCarrierFromFlighId,
} from '../../utils/helpers';
import { useNotifications } from '../../utils/hooks/useNotifications';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Catering from '../../components/FlightDetails/Catering/Catering';
import { catering } from '../../__mocks__/catering';
import { useGetAboveTheWing } from '../../utils/hooks/useGetAboveTheWing';
import { useGetInOutboundConnections } from '../../utils/hooks/useGetInOutboundConnections';
import { useGetDetailsHeader } from '../../utils/hooks/useGetDetailsHeader';
import GaugeContainer from '../../components/FlightDetail/GaugeContainer/GaugeContainer';
import useCheckPermissions, {
  CheckType,
} from '../../utils/hooks/useCheckPermissions';
import { Permission } from '../../utils/generated/graphql';
import useNetworkStatus from '../../utils/hooks/useNetworkStatus';
import ConnectionButtons from './ConnectionButtons';
import FeatureFlagUtil from '../../utils/FeatureFlagUtil';
import { useDeviceBreakpoint } from '../../utils/hooks/useDeviceBreakpoint';
import { calculateFlightNumber } from '../../utils/calculateFlightNumber';
import { useSelector } from 'react-redux';
import { selectUTC } from '../../redux/reducers/settingsReducer';

const aboveWingContainer = 'mobile:px-0 px-24 pt-32 pb-[84px] mobile:pt-0';
const colClassNames = 'tablet:col-span-2 laptop:col-span-2 desktop:col-span-2';
const gridClassNames = classNames('mobile:pt-32', colClassNames);
const flightsHeaderClassNames = classNames(
  'mobile:px-24 mobile:pt-24 font-head-light text-18 text-grey-60 leading-[19px] dark:text-grey-40 mb-[33px] mobile:mb-0 order-1',
  'tablet:col-span-3 laptop:col-span-3 desktop:col-span-3'
);
const hBagClassName =
  'bg-white rounded-8 mobile:mx-24 py-32 mobile:w-auto dark:bg-grey-90 tablet:col-span-1 laptop:col-span-1 desktop:col-span-1 mobile:order-4 order-6 max-h-[350px] w-auto';

const gaugeTitleClassName =
  'font-body-text text-primary text-12 mb-24 dark:text-grey-12 mt-0 bl-0 self-baseline ml-32';

export const classes = {
  firstClass: 1,
  businessClass: 2,
  premiumEconomyClass: 3,
  economyClass: 4,
  infants: 5,
};

const AboveTheWing = () => {
  let { flight } = useParams();
  const isOnline = useNetworkStatus();

  const hasAboveWingDataPermission = useCheckPermissions(
    [Permission.DISPLAY_PROCESS_PAGE_ABOVE_WING_DATA],
    CheckType.FULL
  );

  const [connectionType, setConnectionType] = useState(
    CONNECTION_FLIGHT_TYPE.INBOUND
  );

  const {
    data,
    loading: isFetchingAbove,
    error: errorEncountered,
    responseErrors: responseErrorsAbove,
    loadingFirstTime: isLoadingAbove,
    refetchAboveTheWing,
  } = useGetAboveTheWing(flight, !hasAboveWingDataPermission);

  const {
    data: dataHeader,
    isFetching: isFetchingHeader,
    isLoading: isLoadingHeader,
    error: errorEncounteredHeader,
    responseErrors: responseErrorsHeader,
    refetchDetailsHeader,
  } = useGetDetailsHeader(flight, 'headerAboveTheWing');

  const {
    data: inboundData,
    loading: isFetchingInbound,
    error: errorEncounteredInbound,
    loadingFirstTime: isLoadingInbound,
    responseErrors: responseErrorsInbound,
    refetchInOutboundConnections: refetchInbound,
  } = useGetInOutboundConnections(flight, CONNECTION_FLIGHT_TYPE.INBOUND);

  const {
    data: outboundData,
    loading: isFetchingOutbound,
    error: errorEncounteredOutbound,
    loadingFirstTime: isLoadingOutbound,
    responseErrors: responseErrorsOutbound,
    refetchInOutboundConnections: refetchOutbound,
  } = useGetInOutboundConnections(flight, CONNECTION_FLIGHT_TYPE.OUTBOUND);

  const {
    passengerStatus: {
      capacity = INITIAL_STATE,
      boarded = INITIAL_STATE,
      checkedIn = INITIAL_STATE,
      booked = INITIAL_STATE,
      available = INITIAL_STATE,
      missing = INITIAL_STATE,
      jumps = INITIAL_STATE,
    } = {},
    passengerSpecials: {
      WCHR = INITIAL_STATE,
      WCHC = INITIAL_STATE,
      WCHS = INITIAL_STATE,
      StandBy = INITIAL_STATE,
      DEPA = INITIAL_STATE,
      DEPU = INITIAL_STATE,
      MEDA_MEQT = INITIAL_STATE,
      STRC = INITIAL_STATE,
      UMNR = INITIAL_STATE,
    } = {},
    passengerStatusByClass = {},
    totalCounters: {
      totalPassengers = INITIAL_STATE,
      totalLuggage = INITIAL_STATE,
    } = {},
    handBaggages: {
      cob = INITIAL_STATE,
      hbag = INITIAL_STATE,
      critical = false,
    } = {},
  } = data || {};

  const isFetching =
    isFetchingAbove ||
    isFetchingHeader ||
    isFetchingInbound ||
    isFetchingOutbound;
  const isLoading =
    isLoadingAbove || isLoadingHeader || isLoadingInbound || isLoadingOutbound;
  const [errorsReceived, setErrorsReceived] = useState([]);
  const [allErrorsEncountered, setAllErrorsEncountered] = useState([]);

  useEffect(() => {
    if (!isFetching) {
      let allErrorsReceived = [];
      let allErrorsEncountered = [];

      if (errorEncounteredHeader) {
        allErrorsEncountered.push(errorEncounteredHeader);
      }
      if (errorEncountered) {
        allErrorsEncountered.push(errorEncountered);
      }
      if (errorEncounteredInbound) {
        allErrorsEncountered.push(errorEncounteredInbound);
      }
      if (errorEncounteredOutbound) {
        allErrorsEncountered.push(errorEncounteredOutbound);
      }

      if (allErrorsEncountered.length > 1) {
        allErrorsEncountered = GENERAL_NOTIFICATION_ARRAY;
      }

      setAllErrorsEncountered(allErrorsEncountered);
      if (responseErrorsAbove) {
        allErrorsReceived.push(...responseErrorsAbove);
      }
      if (responseErrorsHeader) {
        allErrorsReceived.push(...responseErrorsHeader);
      }
      if (responseErrorsInbound) {
        allErrorsReceived.push(...responseErrorsInbound);
      }
      if (responseErrorsOutbound) {
        allErrorsReceived.push(...responseErrorsOutbound);
      }
      if (allErrorsReceived.length > 1) {
        allErrorsReceived = GENERAL_NOTIFICATION_ARRAY;
      }
      setErrorsReceived(allErrorsReceived);
    } else {
      setErrorsReceived([]);
    }
  }, [
    isFetching,
    data,
    inboundData,
    outboundData,
    errorEncountered,
    errorEncounteredHeader,
    errorEncounteredInbound,
    errorEncounteredOutbound,
  ]);

  const [error, errorMessage] = getErrorMessage(
    errorsReceived,
    allErrorsEncountered
  );

  const isMobile = useDeviceBreakpoint(DEVICE_TYPE.MOBILE);

  const { connections: inboundConnections = [] } = inboundData || {};

  const { connections: outboundConnections = [] } = outboundData || {};

  const connections =
    connectionType === CONNECTION_FLIGHT_TYPE.INBOUND
      ? inboundConnections
      : outboundConnections;

  const [textInOutbound, setTextInOutbound] = useState(INBOUND.CONNECTIONS);

  const handlerInOutboundButtons = (connectionType) => {
    setConnectionType(connectionType);
    setTextInOutbound(
      connectionType === CONNECTION_FLIGHT_TYPE.OUTBOUND
        ? OUTBOUND.CONNECTIONS
        : INBOUND.CONNECTIONS
    );
  };

  useNotifications(error ? { ...error } : null, errorMessage);

  const {
    flightNumber,
    departureAirport = INITIAL_STATE,
    arrivalAirport = INITIAL_STATE,
    registration = INITIAL_STATE,
    type = INITIAL_STATE,
    bestTimeLogic = { timeLeft: INITIAL_STATE },
    departureTimes = { bestTimeUTC: INITIAL_STATE },
    isCheckedIn,
  } = dataHeader || {};

  const statusByClasses = Object.entries(passengerStatusByClass)
    .filter(([key, value]) => value !== null && key !== '__typename')
    .filter(([_, { empty }]) => !empty)
    .map(
      ([key, { capacity, boarded, accepted, booked, available, standBy }]) => ({
        classId: classes[key],
        seatsNo: capacity,
        boardedNo: boarded,
        checkedInNo: accepted,
        bookedNo: booked,
        standbyNo: standBy,
        availableNo: available,
      })
    )
    .sort((a, b) => a.classId - b.classId);
  const isUTC = useSelector(selectUTC);
  const calculatedFlightNumber = calculateFlightNumber(isUTC, flightNumber);
  const themeColor = getThemeColor(getCarrierFromFlighId(flight));

  const showMCD = dataHeader?.showMCD;
  const MCD = dataHeader?.MCD;

  useCustomHeader({
    headerTypeMobile: 'flight',
    flightNumber: calculatedFlightNumber,
    time: '0:18',
    departureAirport,
    arrivalAirport,
    type,
    registration,
    headerTypeTablet: 'flight',
    bestTimeLogic,
    themeColor,
    departureTimes,
    isCheckedIn,
    MCD: MCD,
    showMCD: showMCD,
  });

  const cobTagArray = critical ? [{ title: COB_WARNING, isWarning: true }] : [];

  return (
    <div className={aboveWingContainer}>
      <div className="w-fit absolute top-24 mobile:top-14 z-50 left-0 right-0 mx-auto">
        {isFetching ? (
          <LoadingSpinner />
        ) : (
          isOnline && (
            <ReloadButton
              refetchFunctions={[
                refetchAboveTheWing,
                refetchDetailsHeader,
                refetchInbound,
                refetchOutbound,
              ]}
            />
          )
        )}
      </div>
      <h1 className={flightsHeaderClassNames}>{ABOVE_WING}</h1>
      <Grid col={[3, 16, 10]} mobile={[1, 0, 24]} className={gridClassNames}>
        <HeroPassanger
          seats={capacity}
          boarded={boarded}
          checkedIn={checkedIn}
          booked={booked}
          available={available}
          missing={missing}
          jumps={jumps}
          className={colClassNames}
          items={statusByClasses}
          themeColor={themeColor}
          type={type}
        />
        <PassengerSpecials
          WCHR={WCHR}
          WCHC={WCHC}
          WCHS={WCHS}
          StandBy={StandBy}
          DEPA={DEPA}
          DEPU={DEPU}
          MEDA_MEQT={MEDA_MEQT}
          STRC={STRC}
          UMNR={UMNR}
        />
        {FeatureFlagUtil.showFeature(
          process?.env?.REACT_APP_TAC_CATERING_FEATURE_DISABLED,
          []
        ) && <Catering catering={catering} />}

        <div className="flex flex-col bg-white dark:bg-grey-90 rounded-8 mobile:rounded-[unset] order-5 desktop:col-span-2 laptop:col-span-2 tablet:col-span-2">
          {isMobile && (
            <p className="font-body-text text-primary text-12 pl-24 pt-24 dark:text-grey-12">
              {FLIGHT_CONNECTIONS.TITLE}
            </p>
          )}
          <ConnectionButtons
            textState={textInOutbound}
            handlerButton={handlerInOutboundButtons}
          />
          <ConnectionFlights
            flightsArray={
              Object.keys(connections).length !== 0 ? connections : []
            }
            totalPassengers={totalPassengers}
            totalBaggages={totalLuggage}
            className={colClassNames}
            isLoading={isLoading}
            connectionType={connectionType}
          />
        </div>

        {FeatureFlagUtil.showFeature(
          process?.env?.REACT_APP_TAC_COB_FEATURE_DISABLED ?? '',
          []
        ) && (
          <GaugeContainer
            title={HBAG_LABEL}
            label={GAUGE_LABEL.HBAG}
            value={hbag}
            max={cob}
            tagArray={cobTagArray}
            className={hBagClassName}
            titleClassName={gaugeTitleClassName}
            gaugeClassName="w-[180px]"
            isLoading={isLoading}
            iconVariant={'baggage'}
          />
        )}
      </Grid>
    </div>
  );
};

export default AboveTheWing;
