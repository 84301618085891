import { useMutation } from '@apollo/client';
import { ADD_MY_FLIGHTS } from '../../graphql/myFlights/my-flights.mutations';
import { FlightSearch, Mutation, Permission } from '../generated/graphql';
import { IMutationResponse } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { addSuccess } from '../../redux/reducers/notificationReducer';
import UtilFactory from '../../factory/UtilFactory';
import { MQTT_TOPIC } from '../../models/MqttTopics';
import {
  addNotification,
  updateFlightNotificationsHistory,
} from '../../redux/reducers/newsfeedReducer';
import { selectUserId } from '../../redux/reducers/authReducer';
import { useGetMyFlightsCached } from './useGetMyFlightsCached';
import { handleNewNotification } from '../notifications/notificationsUtil';
import {
  select24Format,
  selectUTC,
} from '../../redux/reducers/settingsReducer';
import { SEARCH_FLIGHTS } from '../../graphql/flightSearch/flight-search.queries';
import { selectQuery } from '../../redux/reducers/searchReducer';
import useCheckPermissions from './useCheckPermissions';
import { setCountdownToZero } from '../../redux/reducers/reloadReducer';

interface AddMyFlightResponse extends IMutationResponse<string[]> {
  onAddToFavourites: (flightIds: string[]) => Promise<string[]>;
}

interface ICachedFlightSearch {
  flightSearch: { data: FlightSearch[]; errors: null };
}

export const useAddMyFlight = (scope?: string): AddMyFlightResponse => {
  const [addToFavourites, { data, error }] =
    useMutation<Pick<Mutation, 'addMyFlight'>>(ADD_MY_FLIGHTS);
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);
  const useUTC = useSelector(selectUTC);
  const use24Format = useSelector(select24Format);
  const query = useSelector(selectQuery);
  const { refetchMyFlightsCache } = useGetMyFlightsCached(userId);

  const hasFlightNotificationAccess = useCheckPermissions([
    Permission.RECEIVE_FLIGHT_NOTIFICATIONS,
  ]);

  const onMessage = async (topic: string, message: string) => {
    handleNewNotification(
      message,
      userId,
      (userNotification) => dispatch(addNotification(userNotification)),
      useUTC,
      use24Format,
      refetchMyFlightsCache,
      () => dispatch(setCountdownToZero())
    );
    try {
    } catch (error) {
      console.error('Could not parse notification');
      console.error(error);
    }
  };

  const onAddToFavourites = async (flightIds: string[]) => {
    try {
      const response = await addToFavourites({
        variables: {
          input: {
            flights: flightIds,
          },
        },
        optimisticResponse: {
          addMyFlight: {
            flights: flightIds,
            errors: null,
            __typename: 'MyFlightsAddOutput',
          },
        },
        update: (cache, { data: { addMyFlight } }) =>
          cache.updateQuery<ICachedFlightSearch>(
            {
              query: SEARCH_FLIGHTS,
              variables: { genericQuery: query?.toLocaleUpperCase() ?? '' },
            },
            (data) => ({
              flightSearch: {
                ...data.flightSearch,
                data: data.flightSearch.data.map((flight) => ({
                  ...flight,
                  isFavourite: addMyFlight.flights.includes(flight.flightId)
                    ? true
                    : flight.isFavourite,
                })),
              },
            })
          ),
      });
      if (response?.data) {
        if (
          response?.data.addMyFlight?.errors &&
          response?.data.addMyFlight?.errors[0]?.message
        ) {
          dispatch(
            addSuccess(
              response?.data && response?.data?.addMyFlight?.errors[0]?.message
            )
          );

          if (scope === 'process') {
            throw error;
          }
          if (response.data.addMyFlight.flights) {
            return response.data.addMyFlight.flights;
          }
          return [];
        }

        if (
          response.data.addMyFlight?.flights?.length &&
          hasFlightNotificationAccess
        ) {
          dispatch(updateFlightNotificationsHistory());
          UtilFactory.newMessageHandler().subscribe(
            response.data.addMyFlight.flights.map((flightId) => ({
              topic: `${MQTT_TOPIC.FLIGHT}${flightId}`,
              onMessage,
            }))
          );
        }
        refetchMyFlightsCache();

        return response.data.addMyFlight?.flights ?? [];
      }

      return [];
    } catch (error) {
      return [];
    }
  };
  return {
    onAddToFavourites,
    data: data?.addMyFlight?.flights ?? [],
    error: error,
  };
};
