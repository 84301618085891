import { ChatUpdateType } from '../../models/ChatUpdateType';
import { MQTT_TOPIC } from '../../models/MqttTopics';
import {
  Notification,
  NotificationCategory,
  NotificationType,
} from '../generated/graphql';

export class NativeNotificationsUtil {
  static toNotification(message: any, topic: string): Notification | null {
    if (
      topic.includes(MQTT_TOPIC.CHAT) &&
      message?.updateType === ChatUpdateType.NEW_MESSAGE
    ) {
      return {
        _id: '',
        category: NotificationCategory.UNKNOWN,
        createdAt: new Date().toISOString(),
        description: 'Open app to read the message',
        details: {},
        subtitle: 'New Message',
        title: '',
        type: NotificationType.SYSTEM, //TO be changed
      };
    }

    if (
      [
        MQTT_TOPIC.FLIGHT,
        MQTT_TOPIC.ROLE,
        MQTT_TOPIC.STATION,
        MQTT_TOPIC.SYSTEM,
      ].some((t) => topic.includes(t))
    ) {
      return message as Notification;
    }

    return null;
  }

  static formatNotificationsDescription = (
    notification: Notification,
    times: { useUTC: boolean; use24Format: boolean }
  ): string => {
    if (notification?.details?.times) {
      const time = (() => {
        if (times.useUTC && times.use24Format) {
          return notification.details.times.utc24 ?? '';
        }

        if (times.useUTC && !times.use24Format) {
          return notification.details.times.utc12 ?? '';
        }

        if (!times.useUTC && times.use24Format) {
          return notification.details.times.local24 ?? '';
        }

        return notification.details.times.local12 ?? '';
      })();

      return notification.description.replace('%time', time);
    }

    return notification.description;
  };

  static formatNotificationsTitle(
    notification: Notification,
    useUTC: boolean
  ): string {
    if (notification?.details?.flightNumber) {
      const flightNumber = (() => {
        if (useUTC) {
          return notification.details.flightNumber.utc ?? '';
        }
        return notification.details.flightNumber.local ?? '';
      })();

      return notification.title.replace('%flightNumber', flightNumber);
    }

    return notification.title;
  }

  static generateNativeNotification = (
    notification: Notification,
    { useUTC, use24Format }
  ) => {
    const title = NativeNotificationsUtil.formatNotificationsTitle(
      notification,
      useUTC
    );
    const description = `${
      notification.subtitle
    } \n${NativeNotificationsUtil.formatNotificationsDescription(notification, {
      useUTC,
      use24Format,
    })}`;

    const options = {
      body: description,
      icon: '/230320_Turnaround_Compagnion_App_Icon_512px.png',
      vibrate: [
        500, 110, 500, 110, 450, 110, 200, 110, 170, 40, 450, 110, 200, 110,
        170, 40, 500,
      ],
    };

    return { title, options };
  };

  static shouldShow = (
    notification: Notification,
    userStations: string[] | undefined
  ) => {
    return (
      (notification.type === NotificationType.FLIGHT &&
        notification.category !== NotificationCategory.DEPARTED) ||
      (notification.type === NotificationType.ROLE &&
        userStations?.includes(notification.details.station ?? '')) ||
      notification.type === NotificationType.SYSTEM ||
      notification.type === NotificationType.STATION
    );
  };
}
