import { gql } from '@apollo/client';

export const SUBSCRIBE_TO_NOTIFICATION = gql`
  mutation SubscribeToNotifications($input: ISubscribeToNotificationInput!) {
    subscribeToNotifications(input: $input) {
      status
      errors {
        code
        message
        description
      }
    }
  }
`;

export const UNSUBSCRIBE_TO_NOTIFICATION = gql`
  mutation UnsubscribeToNotifications(
    $input: IUnsubscribeToNotificationInput!
  ) {
    unsubscribeToNotifications(input: $input) {
      status
    }
  }
`;
