import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

export type IOnMessage = (topic: string, message: string) => void;

export enum HandlerStatus {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  PENDING = 'pending',
}

export interface ISubscription {
  topic: string;
  onMessage: IOnMessage;
}

export abstract class MessagesHandlerBase {
  protected activeSubscriptions: ISubscription[] = [];
  protected pendingSubscriptions: ISubscription[] = [];
  protected status = HandlerStatus.DISCONNECTED;

  abstract authenticate(clientId: string): Promise<string | null>;
  abstract subscribe(subscriptions: ISubscription[]): void;
  abstract onMessage(title: string, message: string): void;
  abstract unsubscribe(topics: string[]): void;
  abstract connect(
    password: string,
    clientId: string,
    onReconnect?: () => void,
    apolloClient?: ApolloClient<NormalizedCacheObject>
  ): void;
  abstract close(): void;

  getActiveSubscriptions() {
    return this.activeSubscriptions;
  }
}
