import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  HandlerStatus,
  ISubscription,
  MessagesHandlerBase,
} from './MessageHandlerBase';
import {
  ISubscribeToNotificationInput,
  IUnsubscribeToNotificationInput,
  Mutation,
} from '../generated/graphql';
import FeatureFlagUtil from '../FeatureFlagUtil';
import {
  SUBSCRIBE_TO_NOTIFICATION,
  UNSUBSCRIBE_TO_NOTIFICATION,
} from '../../graphql/pushNotifications/pushNotifications.mutation';
import {
  AppMessageAction,
  IServiceWorkerMessage,
  WorkerMessageAction,
} from '../../models/IAppMessage';

class PushHandler extends MessagesHandlerBase {
  private browserSubscription: PushSubscription | null;
  private client: ApolloClient<NormalizedCacheObject> | undefined;
  private RECONNECT_TIMEOUT = 10000;
  private reconnectInterval: ReturnType<typeof setInterval> | null = null;

  urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  authenticate(clientId: string): Promise<string | null> {
    return Promise.resolve(
      process?.env?.REACT_APP_TAC_VAPID_PUBLIC_KEY_KV ?? ''
    );
  }

  onWorkerMessage(event: MessageEvent) {
    // event is a MessageEvent object
    try {
      const data = event.data;

      if (data?.action !== AppMessageAction.PUSH_MESSAGE) {
        console.log(`The service worker sent me a message`, event.data);
      }

      if (
        data &&
        data.message &&
        data.action === AppMessageAction.PUSH_MESSAGE
      ) {
        this.onMessage(
          data.message.topic,
          JSON.stringify(data.message.message)
        );
      }
    } catch (err) {
      console.error(`Failed to parse service worker message`, event.data);
      console.error(err);
    }
  }

  connect(
    password: string,
    clientId: string,
    onReconnect?: () => void,
    apolloClient?: ApolloClient<NormalizedCacheObject>,
    isReconnect?: boolean
  ): void {
    if (this.status === HandlerStatus.PENDING) {
      console.log('Waiting for push subscription to connect!');
      return;
    }

    this.status = HandlerStatus.PENDING;
    this.client = apolloClient;
    if (this.reconnectInterval) {
      clearInterval(this.reconnectInterval);
    }

    console.log('Connecting to push subscription!');

    navigator.serviceWorker.ready
      .then(async (registration) => {
        navigator.serviceWorker.removeEventListener(
          'message',
          this.onWorkerMessage
        );

        navigator.serviceWorker.addEventListener('message', (message) => {
          this.onWorkerMessage(message);
        });

        const existingSubscription =
          await registration.pushManager.getSubscription();

        if (!existingSubscription) {
          this.browserSubscription = await registration.pushManager.subscribe({
            applicationServerKey: this.urlBase64ToUint8Array(password),
            userVisibleOnly: true,
          });
        } else {
          this.browserSubscription = existingSubscription;
        }

        const message: IServiceWorkerMessage = {
          action: WorkerMessageAction.SET_CLIENT,
          message: 'Activate service worker client!',
        };

        registration.active?.postMessage(message);

        console.log('Connected to browser push subscription!');
        this.pendingSubscriptions = this.pendingSubscriptions.concat(
          this.activeSubscriptions
        );
        this.activeSubscriptions = [];

        if (this.pendingSubscriptions) {
          this.subscribe(this.pendingSubscriptions);
        }
        this.status = HandlerStatus.CONNECTED;
      })
      .catch((err) => {
        this.status = HandlerStatus.DISCONNECTED;
        console.error('Could not connect to push subscription!');
        console.error(err);
      })
      .finally(() => {
        if (isReconnect && onReconnect) {
          onReconnect();
        }

        this.reconnectInterval = setInterval(() => {
          this.checkStatus(password, clientId, onReconnect, apolloClient);
        }, this.RECONNECT_TIMEOUT);
      });
  }

  checkStatus(
    password: string,
    clientId: string,
    onReconnect?: () => void,
    apolloClient?: ApolloClient<NormalizedCacheObject>
  ) {
    if (
      !this.browserSubscription ||
      this.status === HandlerStatus.DISCONNECTED
    ) {
      console.log('Reconnecting to push subscription!');
      this.connect(password, clientId, onReconnect, apolloClient);
    }
  }

  subscribe(subscriptions: ISubscription[]): void {
    const notSubscribed = subscriptions.filter(
      (newSubscription) =>
        !this.activeSubscriptions.some(
          (existingSubscription) =>
            existingSubscription.topic === newSubscription.topic
        )
    );

    const SEPARATOR = ' | ';

    if (this.client && this.browserSubscription && notSubscribed.length > 0) {
      const convertedSubscription = JSON.parse(
        JSON.stringify(this.browserSubscription)
      );

      const subscriptionsInput = notSubscribed.map((newSubscription) => ({
        topic: newSubscription.topic,
        subscription: {
          endpoint: this.browserSubscription?.endpoint ?? '',
          keys: {
            auth: convertedSubscription.keys.auth,
            p256dh: convertedSubscription.keys.p256dh,
          },
        },
      }));

      this.client
        .mutate<
          Pick<Mutation, 'subscribeToNotifications'>,
          { input: ISubscribeToNotificationInput }
        >({
          mutation: SUBSCRIBE_TO_NOTIFICATION,
          variables: {
            input: {
              deviceType: navigator?.userAgent ?? 'UNKNOWN',
              subscriptions: subscriptionsInput,
            },
          },
        })
        .then((result) => {
          if (result.data?.subscribeToNotifications?.status) {
            this.activeSubscriptions =
              this.activeSubscriptions.concat(notSubscribed);
            console.log(
              `Subscribed to topics ${subscriptions
                .map((s) => s.topic)
                .join(SEPARATOR)}`
            );
          } else {
            this.pendingSubscriptions =
              this.pendingSubscriptions.concat(notSubscribed);
            console.error(
              `Could not subscribe to topics ${subscriptions
                .map((s) => s.topic)
                .join(SEPARATOR)}`
            );
          }
        })
        .catch((err) => {
          console.error(
            `Could not subscribe to topics ${subscriptions
              .map((s) => s.topic)
              .join(SEPARATOR)}`,
            err
          );
          this.pendingSubscriptions =
            this.pendingSubscriptions.concat(notSubscribed);
        });
    } else {
      this.pendingSubscriptions =
        this.pendingSubscriptions.concat(notSubscribed);
    }
  }

  onMessage(topic: string, message: string) {
    if (
      !FeatureFlagUtil.showFeature(
        process?.env?.REACT_APP_TAC_NOTIFICATIONS_DEBUG_ENABLED ?? '',
        []
      )
    ) {
      console.log(`[${topic}] ${message}`);
    }
    //TODO find solution to get topic
    const subscription = this.activeSubscriptions.find(
      (subscription) => topic === subscription.topic
    );

    if (subscription) {
      subscription.onMessage(topic, message);
    }
  }

  unsubscribe(topics: string[]): void {
    const SEPARATOR = ' | ';

    if (this.client && this.browserSubscription) {
      const convertedSubscription = JSON.parse(
        JSON.stringify(this.browserSubscription)
      );

      const topicsToRemove = topics.map((topic) => ({
        topic,
        subscription: convertedSubscription.keys.p256dh,
      }));

      this.client
        .mutate<
          Pick<Mutation, 'unsubscribeToNotifications'>,
          { input: IUnsubscribeToNotificationInput }
        >({
          mutation: UNSUBSCRIBE_TO_NOTIFICATION,
          variables: {
            input: {
              topics: topicsToRemove,
            },
          },
        })
        .then((result) => {
          if (result.data?.unsubscribeToNotifications?.status) {
            topics.forEach((topic) => {
              const activeSubscriptionIndex =
                this.activeSubscriptions.findIndex(
                  (subscription) => subscription.topic === `${topic}`
                );
              if (activeSubscriptionIndex) {
                this.activeSubscriptions.splice(activeSubscriptionIndex, 1);
              }
            });
            console.log(`Unsubscribed from topic ${topics.join(SEPARATOR)}`);
          } else {
            console.error(
              `Could not unsubscribe from topics ${topics.join(SEPARATOR)}`
            );
          }
        })
        .catch((err) => {
          console.error(
            `Could not unsubscribe from topic ${topics.join(SEPARATOR)}`,
            err
          );
        });
    }
  }

  close(): void {
    if (this.status === HandlerStatus.DISCONNECTED) {
      return;
    }

    if (this.activeSubscriptions.length > 0) {
      this.unsubscribe(
        this.activeSubscriptions.map((subscription) => subscription.topic)
      );
    }

    this.activeSubscriptions = [];

    if (this.browserSubscription) {
      this.browserSubscription.unsubscribe();
    }
    this.status = HandlerStatus.DISCONNECTED;
  }
}

export default PushHandler;
