import { createSlice, current } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  ChatConversation,
  ChatMessage,
  ChatMessagesOutput,
  MessageStatus,
} from '../../utils/generated/graphql';
import { createSelector } from '@reduxjs/toolkit';
import { MessageSending } from '../../components/Chat/FlightChat/ChatWindow/ChatWindow';
import { CHAT_TOPIC_SEPARATOR, TEAM_CHAT } from '../../utils/constants';
import moment from 'moment';

const CABA_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

interface CheckedInUser {
  userId: string;
  phone: string;
  technicalRole: string;
  __typename: string;
}

export interface CheckedInResponsableRole {
  name: string;
  checkedInUsers: CheckedInUser[];
  __typename?: string;
}

interface IChatMessagesReducerState {
  chatMessages: ChatMessagesOutput;
  newChatMessagesReceived: boolean;
  updatedChatMessageReceived: boolean;
}
const initialState: IChatMessagesReducerState = {
  chatMessages: {
    conversations: [],
    lastStatusUpdate: `${moment
      .utc()
      .subtract(1, 'day')
      .format(CABA_TIME_FORMAT)}Z`,
  },
  newChatMessagesReceived: true,
  updatedChatMessageReceived: false,
};

const chatIdCombinations = (
  firstRole: string,
  secondRole: string,
  flightId: string
) => {
  return [
    `${firstRole}-${secondRole}${CHAT_TOPIC_SEPARATOR}${flightId}`,
    `${secondRole}-${firstRole}${CHAT_TOPIC_SEPARATOR}${flightId}`,
  ];
};

export const chatReducer = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatMessages: (state, action) => {
      state.chatMessages = action.payload;
      state.newChatMessagesReceived = false;
      state.updatedChatMessageReceived = false;
    },

    setChatNewMessages: (state, action) => {
      let currentState = current(state).chatMessages.conversations;
      const newMessages: ChatConversation[] =
        action.payload?.conversations ?? [];

      newMessages.forEach((newChat: ChatConversation) => {
        const splittedTopic = newChat?.chatId?.split(CHAT_TOPIC_SEPARATOR);
        const technicalRoles = splittedTopic[0]?.split('-') ?? '';
        const newChatIdInverted = `${technicalRoles[1] ?? ''}-${
          technicalRoles[0] ?? ''
        }${CHAT_TOPIC_SEPARATOR}${splittedTopic[1] ?? ''}`;
        const index = newChat.messages.length
          ? currentState.findIndex(
              (oldchat) =>
                oldchat?.chatId === newChat?.chatId ||
                oldchat?.chatId === newChatIdInverted
            )
          : -1;

        if (index !== -1) {
          const allMessagesUnique: ChatMessage[] = [
            ...currentState[index].messages,
            ...newChat.messages,
          ].filter((value, index, self) => {
            return (
              self.findIndex(
                (v) =>
                  v.messageId === value.messageId ||
                  ((!v.messageId || !value.messageId) && v.body === value.body)
              ) === index
            );
          });

          //update status for new received messages
          newChat.messages.forEach((message) => {
            let indexNewMessage = -1;
            allMessagesUnique.forEach((uniqueMessage, index) => {
              if (
                (uniqueMessage.messageId &&
                  uniqueMessage.messageId === message.messageId) ||
                (!uniqueMessage.messageId &&
                  uniqueMessage.body === message.body)
              ) {
                indexNewMessage = index;
                return;
              }
            });

            if (indexNewMessage !== -1) {
              allMessagesUnique[indexNewMessage] = {
                ...allMessagesUnique[indexNewMessage],
                status: message.status,
                messageId: message.messageId,
              };
            }
          });
          state.chatMessages.conversations[index].chatId = newChat.chatId;
          state.chatMessages.conversations[index].messages = allMessagesUnique;
        } else {
          state.chatMessages.conversations.push(newChat);
        }
      });
      state.chatMessages.lastStatusUpdate =
        action.payload?.lastStatusUpdate ?? null;
      state.newChatMessagesReceived = false;
      state.updatedChatMessageReceived = false;
    },

    setNewChatMessagesReceived: (state) => {
      state.newChatMessagesReceived = true;
    },
    setUpdatedChatMessagesReceived: (state) => {
      state.updatedChatMessageReceived = true;
    },

    updateChatMessages: (state, action) => {
      const { chatId, message, flightId } = action.payload;
      const currentConversation = state.chatMessages.conversations?.find(
        (conv) => {
          return conv?.chatId === chatId;
        }
      );
      if (currentConversation) {
        currentConversation?.messages?.push(message);
      } else {
        state.chatMessages.conversations.push({
          chatId,
          flightId,
          flightNumber: { local: '', utc: '' },
          lastConversationId: '',
          lastTopic: '',
          messages: [message],
        });
      }
    },
    updateMessageStatus: (state, action) => {
      const { chatId, message, status, messageId } = action.payload;
      const currentConversation = state.chatMessages.conversations?.find(
        (conv) => {
          return conv?.chatId === chatId;
        }
      );
      if (currentConversation) {
        let currentMessage;
        currentMessage = currentConversation?.messages?.find((msg) => {
          return msg.messageId === messageId;
        });
        if (!currentMessage) {
          currentMessage = currentConversation?.messages?.find((msg) => {
            if (
              msg.isMe &&
              (status === MessageStatus.FAILED || status === MessageStatus.SENT)
            ) {
              const isMessageNeedingUpdate =
                msg.status === MessageSending.SENDING;
              return msg.body === message && isMessageNeedingUpdate;
            } else {
              const isMessageNeedingUpdate =
                msg.status === MessageSending.SENDING ||
                msg.status === MessageStatus.FAILED ||
                (!msg.isMe && msg.status !== MessageStatus.READ);
              return msg.body === message && isMessageNeedingUpdate;
            }
          });
        }
        if (currentMessage) {
          currentMessage.status = status;
          if (messageId) currentMessage.messageId = messageId;
        }
      }
    },
    removeChatMessages: (state, action) => {
      const { chatId, messageId } = action.payload;
      const currentConversation = state.chatMessages.conversations?.find(
        (conv) => {
          return conv?.chatId === chatId;
        }
      );
      if (currentConversation && currentConversation.messages) {
        currentConversation.messages = currentConversation?.messages?.filter(
          (msg) => {
            return msg.messageId !== messageId;
          }
        );
      }
    },
  },
});

export const {
  setChatMessages,
  setChatNewMessages,
  setNewChatMessagesReceived,
  setUpdatedChatMessagesReceived,
  updateChatMessages,
  updateMessageStatus,
  removeChatMessages,
} = chatReducer.actions;

export const selectLastStatusUpdate = (state: RootState) =>
  state.chatReducer.chatMessages?.lastStatusUpdate ?? null;
export const selectChatMessages = (state: RootState) =>
  state.chatReducer.chatMessages;
export const selectConversationById = (chatId: string) =>
  createSelector(
    (state: RootState) => state.chatReducer.chatMessages?.conversations,
    (conversations) =>
      conversations?.find((conv) => conv?.chatId === chatId)?.messages
  );

export const selectChatIdByRolesFlight = (
  firstRole: string,
  secondRole: string,
  flightId: string
) =>
  createSelector(
    (state: RootState) => state.chatReducer.chatMessages?.conversations,
    (conversations) => {
      const [chatId, chatIdRolesReversed] = chatIdCombinations(
        firstRole,
        secondRole,
        flightId
      );
      if (
        firstRole.includes(TEAM_CHAT.roleGroup) ||
        secondRole.includes(TEAM_CHAT.roleGroup)
      ) {
        return (
          conversations?.find(
            (conv) =>
              conv.chatId.includes(TEAM_CHAT.roleGroup) &&
              conv.chatId.includes(flightId)
          )?.chatId ?? chatId
        );
      }
      return (
        conversations?.find(
          (conv) =>
            conv?.chatId === chatId || conv?.chatId === chatIdRolesReversed
        )?.chatId ?? chatId
      );
    }
  );

export const selectNewChatMessagesReceived = (state: RootState) =>
  state.chatReducer.newChatMessagesReceived;
export const selectUpdatedChatMessagesReceived = (state: RootState) =>
  state.chatReducer.updatedChatMessageReceived;
export default chatReducer.reducer;
