import moment from 'moment';
import { DEPARTURE_SORTING, TIME_SORTING } from '../constants';
import {
  fetchApi,
  getAccessToken,
  getIdToken,
  getSettingsData,
} from '../helpers';
import {
  CachedMyFlight,
  Notification as TacNotification,
  NotificationCategory,
  NotificationType,
  Permission,
  Role,
} from '../generated/graphql';
import { createFlyInNotification } from './flyInNotificationsUtil';
import FeatureFlagUtil from '../FeatureFlagUtil';
import { NativeNotificationsUtil } from './NativeNotificationsUtil';

export const formatNotificationTime = (date, use24 = true) => {
  const notificationDate = moment(date);
  const currentTime = moment();

  const timeDifferenceMinutes = currentTime.diff(notificationDate, 'minutes');

  if (timeDifferenceMinutes <= 1) {
    return 'now';
  }

  const format = use24 ? 'H:mm' : 'h:mm a';

  return notificationDate.format(format);
};

export const readNotification = async (
  baseUrl: string,
  id: string,
  userId: string
) => {
  await fetchApi(`${baseUrl}/notifications/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
      'ID-Token': getIdToken(),
    },
    body: JSON.stringify({ userNotification: { read: true }, userId }),
  });
};

export const handleTimeSort = (
  newsfeed,
  sortingCriteria = TIME_SORTING.DSC
) => {
  if (![TIME_SORTING.DSC, TIME_SORTING.ASC].includes(sortingCriteria)) {
    return newsfeed;
  }
  if (!newsfeed) {
    return;
  }

  const sortingFunctions = {
    'time-ASC': (a, b) =>
      new Date(b[1][0].notification.sentAt).getTime() -
      new Date(a[1][0].notification.sentAt).getTime(),
    'time-DSC': (a, b) =>
      new Date(a[1][0].notification.sentAt).getTime() -
      new Date(b[1][0].notification.sentAt).getTime(),
  };

  return Object.entries(newsfeed)
    .sort(sortingFunctions[sortingCriteria])
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
};

export const handleNotificationTimeSort = (
  newsfeed,
  sortingCriteria = TIME_SORTING.DSC
) => {
  if (![TIME_SORTING.DSC, TIME_SORTING.ASC].includes(sortingCriteria)) {
    return newsfeed;
  }
  if (!newsfeed) {
    return;
  }

  const sortingFunctions = {
    'time-ASC': (a, b) =>
      new Date(b.notification.sentAt).getTime() -
      new Date(a.notification.sentAt).getTime(),
    'time-DSC': (a, b) =>
      new Date(a.notification.sentAt).getTime() -
      new Date(b.notification.sentAt).getTime(),
  };

  const sortedNewsfeed = {};
  for (const key in newsfeed) {
    const sortedArray = [...newsfeed[key]].sort(
      sortingFunctions[sortingCriteria]
    );
    sortedNewsfeed[key] = sortedArray;
  }
  return sortedNewsfeed;
};

export const handleDepartureSort = (
  newsfeed,
  stationsList,
  sortingCriteria = DEPARTURE_SORTING.DSC,
  myFlightsOrder
) => {
  if (
    ![DEPARTURE_SORTING.DSC, DEPARTURE_SORTING.ASC].includes(sortingCriteria)
  ) {
    return newsfeed;
  }

  if (!newsfeed) {
    return;
  }

  const myFlightsOrderCopy = [...myFlightsOrder];

  if (sortingCriteria.includes('ASC')) {
    myFlightsOrderCopy.reverse();
  }

  const sortedNotifications = {};

  myFlightsOrderCopy.forEach((flightId) => {
    if (newsfeed[flightId]) {
      sortedNotifications[flightId] = newsfeed[flightId];
    }
  });

  if (newsfeed[NotificationType.SYSTEM]) {
    sortedNotifications[NotificationType.SYSTEM] =
      newsfeed[NotificationType.SYSTEM];
  }

  stationsList.forEach((station) => {
    if (newsfeed[station]) {
      sortedNotifications[station] = newsfeed[station];
    }
  });

  const keysNewsfeed = Object.keys(newsfeed);
  const keysSorted = Object.keys(sortedNotifications);

  const remainingInNewsfeed = keysNewsfeed.filter(
    (key) => !keysSorted.includes(key)
  );

  remainingInNewsfeed.forEach(
    (key) => (sortedNotifications[key] = newsfeed[key])
  );

  return Object.keys(sortedNotifications).length !== 0
    ? sortedNotifications
    : newsfeed;
};

export const sendNativeNotificationFromMqtt = async (
  notification: TacNotification,
  { useUTC, use24Format }
) => {
  if (!window?.Notification || Notification?.permission !== 'granted') {
    return;
  }

  if (
    FeatureFlagUtil.showFeature(
      process?.env?.REACT_APP_TAC_NATIVE_NOTIFICATIONS_FEATURE_DISABLED ?? '',
      []
    ) &&
    'serviceWorker' in navigator
  ) {
    const nativeNotification =
      NativeNotificationsUtil.generateNativeNotification(notification, {
        useUTC,
        use24Format,
      });

    if (nativeNotification) {
      navigator.serviceWorker.ready.then((registration) =>
        registration.showNotification(
          nativeNotification.title,
          nativeNotification.options
        )
      );
    }
  }
};

const isMutedCheckedInByType = (
  type: NotificationType,
  cachedFlights: CachedMyFlight[],
  flightId: string
) => {
  const userSettings = getSettingsData();

  switch (type) {
    case NotificationType.STATION:
      return {
        isMuted: userSettings?.stationMuted || false,
        isCheckedIn: false,
        isNativeMuted:
          userSettings?.nativeNotificationsMuted ||
          userSettings?.stationMuted ||
          false,
      };
    case NotificationType.SYSTEM:
      return {
        isMuted: !userSettings?.nativeNotificationsMuted,
        isCheckedIn: false,
        isNativeMuted: userSettings?.nativeNotificationsMuted ?? false,
      };
    case NotificationType.ROLE:
      return {
        isMuted: !userSettings?.nativeNotificationsMuted,
        isCheckedIn: false,
        isNativeMuted: userSettings?.nativeNotificationsMuted ?? false,
      };
    case NotificationType.FLIGHT: {
      const flight = cachedFlights.find(
        (flight) => flight.flightId === flightId
      );
      return {
        isMuted: flight?.notificationMuted ?? false,
        isCheckedIn: flight?.isCheckedIn ?? false,
        isNativeMuted:
          userSettings?.nativeNotificationsMuted ||
          flight?.notificationMuted ||
          false,
      };
    }
    default:
      return { isMuted: false, isCheckedIn: false, isNativeMuted: false };
  }
};

export const handleNewNotification = async (
  message: string,
  userId: string,
  dispatchAddFlightNotification: (userNotification: {
    notification: TacNotification;
    userId: string;
  }) => void,
  useUTC: boolean,
  use24Format: boolean,
  refetchMyFlightsCache?: () => Promise<CachedMyFlight[]>,
  dispatchSetCountdownToZero?: () => void,
  userStations?: string[]
) => {
  const notification = JSON.parse(message) as TacNotification;
  const pagesNames = ['process', 'above', 'below', 'team'];

  if (
    notification.type === NotificationType.FLIGHT &&
    (notification.category === NotificationCategory.NEW_BOARDING ||
      notification.category === NotificationCategory.FLIGHT_DELAYED ||
      notification.category === NotificationCategory.OFF_BLOCK)
  ) {
    const pathElements = window.location?.pathname?.split('/') ?? [];
    const flightId = pathElements?.[2] ?? '';
    const pageName = pathElements?.[3] ?? '';

    if (
      dispatchSetCountdownToZero &&
      flightId === notification.details.flightId &&
      pagesNames.includes(pageName)
    ) {
      dispatchSetCountdownToZero();
    }
  }

  const shouldShow = NativeNotificationsUtil.shouldShow(
    notification,
    userStations
  );

  shouldShow && dispatchAddFlightNotification({ userId, notification });

  const cachedFlights =
    notification.type === NotificationType.FLIGHT && refetchMyFlightsCache
      ? await refetchMyFlightsCache()
      : [];

  const { isMuted, isCheckedIn, isNativeMuted } = isMutedCheckedInByType(
    notification.type,
    cachedFlights,
    notification.details.flightId ?? ''
  );

  if (shouldShow) {
    !isMuted &&
      isNativeMuted &&
      createFlyInNotification(notification, isCheckedIn, useUTC, use24Format);

    //Native http push are handled in service-worker
    !isNativeMuted &&
      !FeatureFlagUtil.showFeature(
        process?.env?.REACT_APP_TAC_HTTP_PUSH_FEATURE_DISABLED ?? '',
        []
      ) &&
      sendNativeNotificationFromMqtt(notification, { useUTC, use24Format });
  }
};

export const getNotificationTypesFromRoles = (
  userRoles: Role[]
): NotificationType[] | [] => {
  const permissions = [
    ...new Set(userRoles.flatMap((role) => role.permissions)),
  ];

  const notificationTypes: NotificationType[] = [];
  if (permissions.includes(Permission.READ_ALL_NOTIFICATIONS)) {
    notificationTypes.push(NotificationType.STATION, NotificationType.SYSTEM);
  }

  if (permissions.includes(Permission.READ_STATION_NOTIFICATIONS)) {
    !notificationTypes.includes(NotificationType.STATION) &&
      notificationTypes.push(NotificationType.STATION);
  }

  if (
    FeatureFlagUtil.showFeature(
      process?.env?.REACT_APP_TAC_ROLE_NOTIFICATIONS_FEATURE_DISABLED ?? '',
      userRoles
    ) &&
    permissions.includes(Permission.READ_ROLE_NOTIFICATIONS)
  ) {
    !notificationTypes.includes(NotificationType.ROLE) &&
      notificationTypes.push(NotificationType.ROLE);
  }

  return notificationTypes;
};
