import FeatureFlagUtil from '../utils/FeatureFlagUtil';
import MessagesHandler from '../utils/notifications/MessageHandler';
import { MessagesHandlerBase } from '../utils/notifications/MessageHandlerBase';
import PushHandler from '../utils/notifications/PushHandler';

class UtilFactory {
  private static messageHandler: MessagesHandlerBase;

  static init() {
    UtilFactory.messageHandler = UtilFactory.newMessageHandler();
  }

  static newMessageHandler() {
    if (!UtilFactory.messageHandler) {
      UtilFactory.messageHandler = FeatureFlagUtil.showFeature(
        process?.env?.REACT_APP_TAC_HTTP_PUSH_FEATURE_DISABLED ?? '',
        []
      )
        ? new PushHandler()
        : new MessagesHandler();
    }

    return UtilFactory.messageHandler;
  }
}

export default UtilFactory;
