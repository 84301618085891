import { Dispatch } from '@reduxjs/toolkit';
import { MQTT_TOPIC } from '../../models/MqttTopics';
import UtilFactory from '../../factory/UtilFactory';
import { handleNewNotification } from './notificationsUtil';
import { addNotification } from '../../redux/reducers/newsfeedReducer';

const messageHandler = UtilFactory.newMessageHandler();

export const subscribeToStations = (
  userId: string,
  stations: string[],
  useUTC: boolean,
  use24: boolean,
  dispatch: Dispatch
) => {
  messageHandler.subscribe(
    stations.map((station) => ({
      topic: `${MQTT_TOPIC.STATION}${station}`,
      onMessage: async (topic: string, message: string) => {
        handleNewNotification(
          message,
          userId,
          (userNotification) => dispatch(addNotification(userNotification)),
          useUTC,
          use24
        );
        try {
        } catch (error) {
          console.error('Could not parse notification');
          console.error(error);
        }
      },
    }))
  );
};
