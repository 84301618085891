import UtilFactory from '../../factory/UtilFactory';
import { MQTT_TOPIC } from '../../models/MqttTopics';
import { handleNewNotification } from './notificationsUtil';
import { addNotification } from '../../redux/reducers/newsfeedReducer';
import { Dispatch } from '@reduxjs/toolkit';
import { FlightCheckinRoles } from '../hooks/useGetCheckedInRoles';
import { setCountdownToZero } from '../../redux/reducers/reloadReducer';

const messageHandler = UtilFactory.newMessageHandler();

export const onMessage =
  (
    userId: string,
    dispatch: Dispatch,
    useUTC: boolean,
    use24Format: boolean,
    refetch?,
    userStations?: string[]
  ) =>
  async (topic: string, message: string) => {
    try {
      handleNewNotification(
        message,
        userId,
        (userNotification) => dispatch(addNotification(userNotification)),
        useUTC,
        use24Format,
        refetch,
        () => dispatch(setCountdownToZero()),
        userStations
      );
    } catch (error) {
      console.error('Could not parse notification');
      console.error(error);
    }
  };

interface ISubscribeToFlight {
  flightId: string;
  userId: string;
  dispatch: Dispatch;
  useUTC: boolean;
  use24Format: boolean;
  refetch?;
}

export const subscribeToFlights = (subscriptions: ISubscribeToFlight[]) => {
  UtilFactory.newMessageHandler().subscribe(
    subscriptions.map((subscription) => ({
      topic: `${MQTT_TOPIC.FLIGHT}${subscription.flightId}`,
      onMessage: onMessage(
        subscription.userId,
        subscription.dispatch,
        subscription.useUTC,
        subscription.use24Format,
        subscription.refetch
      ),
    }))
  );
};

interface ISubscribeToCheckedIn {
  flightId: string;
  refetchCheckInRoles?: () => Promise<FlightCheckinRoles[]>;
}

export const subscribeToFlightsCheckin = (
  subscriptions: ISubscribeToCheckedIn[]
) => {
  UtilFactory.newMessageHandler().subscribe(
    subscriptions.map((subscription) => ({
      topic: `${MQTT_TOPIC.CHECKIN}${subscription.flightId}`,
      onMessage: async () => {
        if (subscription.refetchCheckInRoles) {
          await subscription.refetchCheckInRoles();
        }
      },
    }))
  );
};

export const unsubscribeToFlightCheckin = (flightId: string) => {
  const topic = `${MQTT_TOPIC.CHECKIN}${flightId}`;
  messageHandler.unsubscribe([topic]);
};

export const unsubscribeToFlight = (flightId: string) => {
  const topic = `${MQTT_TOPIC.FLIGHT}${flightId}`;
  messageHandler.unsubscribe([topic]);
};
