import React from 'react';
import classNames from 'classnames';

interface ITailwingProps {
  airlineKey: string;
  size?: 'xs' | 's' | 'm' | 'l';
  isOpaque?: boolean;
  className?: string;
}

const Tailwing = ({
  airlineKey,
  size = 'l',
  isOpaque = false,
  className,
  ...others
}: ITailwingProps) => {
  let width = 0,
    height = 0;

  switch (size) {
    case 'xs':
      width = 84;
      height = 75;
      break;
    case 's':
      width = 144;
      height = 124;
      break;
    case 'm':
      width = 191;
      height = 164;
      break;
    case 'l':
      width = 222;
      height = 191;
      break;
  }

  const wingsPath = {
    '4y': '4Y.webp',
    ac: 'AC.webp',
    ca: 'CA.webp',
    en: 'EN.webp',
    lh: 'LH.webp',
    lx: 'LX.webp',
    vl: 'LH.webp',
    nh: 'NH.webp',
    os: 'OS.webp',
    sn: 'SN.webp',
    sq: 'SQ.webp',
    ua: 'UA.webp',
    wk: 'WK.webp',
  };

  if (airlineKey in wingsPath) {
    return (
      <img
        src={`/wings/${wingsPath[airlineKey]}`}
        style={{ width, height }}
        alt={`${airlineKey}-wing`}
        className={classNames(
          className,
          'object-cover [overflow-clip-margin:unset]',
          {
            'opacity-25': isOpaque,
          }
        )}
      />
    );
  }
};

export default Tailwing;
