export enum AppMessageAction {
  CLIENT_SET = 'CLIENT_SET',
  PUSH_MESSAGE = 'PUSH_MESSAGE',
}

export enum WorkerMessageAction {
  SET_CLIENT = 'SET_CLIENT',
  UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS',
}

export interface IAppMessage {
  action: AppMessageAction;
  message: string | object;
}

export interface IServiceWorkerMessage {
  action: WorkerMessageAction;
  message: string | object;
}
