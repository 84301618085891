import { useQuery, useLazyQuery } from '@apollo/client';
import {
  Query,
  ChatMessagesOutput,
  NotificationCategory,
  NotificationType,
  Notification,
} from '../generated/graphql';
import { IDataResponse } from '../types';
import { useSelector } from 'react-redux';
import { CHAT_MESSAGES } from '../../graphql/chat/chat-messages.queries.ts';
import {
  selectChatMessages,
  setChatMessages,
  setChatNewMessages,
} from '../../redux/reducers/chatReducer.ts';
import { AppDispatch } from '../../redux/store.ts';
import { createFlyInNotification } from '../notifications/flyInNotificationsUtil.tsx';
import {
  select24Format,
  selectUTC,
} from '../../redux/reducers/settingsReducer.ts';
import { NEW_CHAT_MESSAGE_SUBTITLE } from '../constants.ts';
import { selectChatMuted } from '../../redux/reducers/settingsReducer';
import { getSettingsData, isUserLoggedIn } from '../helpers.js';
import { sendNativeNotificationFromMqtt } from '../notifications/notificationsUtil.ts';
import { ChatUpdateType } from '../../models/ChatUpdateType.ts';
import { calculateFlightNumber } from '../calculateFlightNumber.tsx';
import FeatureFlagUtil from '../FeatureFlagUtil.ts';

interface IGetChatMessagesLazy
  extends IDataResponse<ChatMessagesOutput | null> {
  refetchChatMessages: (dispatch: AppDispatch) => void;
  getChatMessages: (
    lastStatusUpdate: string | null,
    role: string,
    dispatch: AppDispatch,
    type: ChatUpdateType
  ) => Promise<ChatMessagesOutput | null>;
}

const MAX_CHAT_FLY_IN_CHARACTERS = 80;

export const useGetChatMessages = (
  lastStatusUpdate: string | null,
  role: string,
  type: ChatUpdateType
): IDataResponse<ChatMessagesOutput | null> => {
  const isLoggedIn = isUserLoggedIn();
  const { data, loading, error, refetch } = useQuery<
    Pick<Query, 'chatMessages'>
  >(CHAT_MESSAGES, {
    variables: {
      lastStatusUpdate,
      role,
      type,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-only',
    skip: !isLoggedIn,
  });

  return {
    data: data?.chatMessages ?? null,
    loading,
    error,
  };
};

export const useGetChatMessagesLazy = (): IGetChatMessagesLazy => {
  const [onGetChatMessages, { data, loading, error, refetch }] = useLazyQuery<
    Pick<Query, 'chatMessages'>
  >(CHAT_MESSAGES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const useUTC = useSelector(selectUTC);
  const use24Format = useSelector(select24Format);
  const isChatMuted = useSelector(selectChatMuted);
  const chatMessagesIds = useSelector(
    selectChatMessages
  )?.conversations?.flatMap(({ messages }) =>
    messages.map((message) => message.messageId)
  );
  const userSettings = getSettingsData();

  const isNativeMuted =
    userSettings?.nativeNotificationsMuted || isChatMuted || false;

  const getChatMessages = async (
    lastStatusUpdate: string | null,
    role: string,
    dispatch: AppDispatch,
    type: ChatUpdateType
  ) => {
    const result = await onGetChatMessages({
      variables: {
        lastStatusUpdate,
        role,
        type,
      },
    });

    const chatMessages = result?.data?.chatMessages;

    if (lastStatusUpdate !== null) {
      if (type === ChatUpdateType.NEW_MESSAGE) {
        chatMessages?.conversations
          ?.flatMap(({ flightNumber, messages }) =>
            messages.map((message) => ({ message, flightNumber }))
          )
          .forEach((chat) => {
            const description = chat.message?.body ?? '';
            const messageId = chat.message?.messageId ?? '';
            if (!chat.message?.isMe && !chatMessagesIds.includes(messageId)) {
              const notification: Notification = {
                _id: '',
                category: NotificationCategory.UNKNOWN,
                createdAt: new Date().toISOString(),
                description:
                  description.length > MAX_CHAT_FLY_IN_CHARACTERS
                    ? `${description.slice(0, MAX_CHAT_FLY_IN_CHARACTERS)}...`
                    : description,
                details: {},
                subtitle: NEW_CHAT_MESSAGE_SUBTITLE + chat.message?.userName,
                title: calculateFlightNumber(useUTC, chat.flightNumber),
                type: NotificationType.SYSTEM,
              };

              !isChatMuted &&
                isNativeMuted &&
                createFlyInNotification(
                  notification,
                  true, // always true because I am checked in
                  useUTC,
                  use24Format
                );

              //?????? How do we handle chat notificaitons
              // Native http push are handled in service-worker
              !isNativeMuted &&
                !FeatureFlagUtil.showFeature(
                  process?.env?.REACT_APP_TAC_HTTP_PUSH_FEATURE_DISABLED ?? '',
                  []
                ) &&
                sendNativeNotificationFromMqtt(notification, {
                  useUTC,
                  use24Format,
                });
            }
          });
      }
      dispatch(setChatNewMessages(chatMessages));
    }
    if (lastStatusUpdate === null) {
      dispatch(setChatMessages(chatMessages));
    }

    return chatMessages ?? null;
  };

  const refetchChatMessages = async (dispatch: AppDispatch) => {
    const result = await refetch();
    dispatch(setChatMessages(result?.data?.chatMessages ?? null));
  };

  return {
    data: data?.chatMessages ?? null,
    loading,
    error,
    getChatMessages,
    refetchChatMessages,
  };
};
