import { gql } from '@apollo/client';

export const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationFragment on Notification {
    _id
    title
    subtitle
    description
    type
    category
    details {
      flightId
      flightNumber {
        utc
        local
      }
      station
      times {
        local12
        local24
        utc12
        utc24
      }
    }
    createdAt
    updatedAt
    sentAt
  }
`;
