// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
  if ('serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(
      process.env.REACT_APP_TAC_AUTHORIZATION_REDIRECT_URL ?? '',
      window.location.href
    );
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if REACT_APP_TAC_AUTHORIZATION_REDIRECT_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    navigator.serviceWorker.getRegistration().then(function (registration) {
      // There's an active SW, but no controller for this tab.
      if (
        registration &&
        registration.active &&
        !navigator.serviceWorker.controller
      ) {
        // Perform a soft reload to load everything from the SW and get
        // a consistent set of resources.
        console.log("There's an active SW, but no controller for this tab.");
        window.location.reload();
      }
    });

    window.addEventListener('load', () => {
      const swUrl = `${process.env.REACT_APP_TAC_AUTHORIZATION_REDIRECT_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://cra.link/PWA'
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.addEventListener('updatefound', () => {
        const newWorker = registration.installing;

        // Listen for the state change of the new worker
        newWorker?.addEventListener('statechange', () => {
          if (
            newWorker.state === 'installed' &&
            navigator.serviceWorker.controller
          ) {
            // New SW is installed and waiting to activate
            // Notify the user or immediately activate
            console.log('New service worker available.');

            // Send a message to the waiting service worker to activate it
            newWorker.postMessage({ type: 'SKIP_WAITING' });
          }
        });
      });
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });

  navigator.serviceWorker.addEventListener('controllerchange', () => {
    console.log('New service worker has taken control.');
  });

  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      if (
        registration.active &&
        !registration.active.scriptURL.endsWith('/service-worker.js')
      ) {
        // Unregister any old service worker with a different script URL
        registration.unregister().then(() => {
          console.log(
            `Old service worker unregistered: ${registration.active?.scriptURL}`
          );
        });
      }
    });
  });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: {
      'Service-Worker': 'script',
      'Content-Type': 'application/javascript',
    },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      //const contentType = response.headers.get('content-type');
      if (response.status === 404) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        console.log('Registration valid');
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
