import React from 'react';
//import { useSubscribeToNotificationsLazy } from '../../utils/hooks/useSubscribeToPush';
//import { useSendPushNotificationLazy } from '../../utils/hooks/useSendPushNotification';

const PushNotifications = () => {
  //const { onSavePushSubscription } = useSubscribeToPushLazy();
  //const { onSendPushNotification } = useSendPushNotificationLazy();

  navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
    // Do we already have a push message subscription?
    serviceWorkerRegistration.pushManager
      .getSubscription()
      .then((subscription) => {
        // Enable any UI which subscribes / unsubscribes from
        // push messages.

        if (!subscription) {
          console.log('No push subscription');
          // We aren't subscribed to push, so set UI
          // to allow the user to enable push
          return;
        }

        console.dir(subscription);
      })
      .catch((err) => {
        console.error(`Error during getSubscription(): ${err}`);
      });
  });

  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  const handleSaveSubscription = async () => {
    console.log('subscribing');
    navigator.serviceWorker.ready.then(async (registration) => {
      const subscription: PushSubscription =
        await registration.pushManager.subscribe({
          applicationServerKey: urlBase64ToUint8Array(
            'BLnUKxF_ANFeYKqUMK6faXe1jE_HfgtrYWWHUUT2EsvozY5DhDCS942DZ1ZxQQn9d1yonKf5TUg3qHVxzyZl5i4'
          ),
          userVisibleOnly: true,
        });

      const objectSubscription = JSON.parse(JSON.stringify(subscription));

      const savePushInput = {
        endpoint: objectSubscription.endpoint ?? '',
        keys: objectSubscription.keys ?? {},
      };
      console.dir('----------', savePushInput);
      //const response = await onSavePushSubscription(savePushInput);

      //console.log('Response from save subscription', response);
      window.location.reload();
    });
  };

  const handleSendPushNotification = async () => {
    //const response = await onSendPushNotification();
    //console.log('Response from sendNotification', response);
  };

  return (
    <div className="mt-[150px]">
      <p>This is dummy app to test native notifications</p>
      <button
        className="border-black border-1 h-[100px] mr-[40px]"
        onClick={handleSaveSubscription}>
        Subscribe to push
      </button>
      <button
        className="border-black border-1  h-[100px]"
        onClick={handleSendPushNotification}>
        Send notification
      </button>
    </div>
  );
};
export default PushNotifications;
